import React, { useEffect } from 'react'

const Policy = () => {
    useEffect(() => {
        document.title = "Политика конфиденциальности"
    }, [])
    return (
        <main>
            <section id="sec-8" className="mb-8">
                <div className="container">
                    <h1>Политика обработки персональных данных (действует с 01 февраля 2022 г.)</h1>
                    <h5>1.Общие положения</h5>
                    <p>1.Настоящая Политика обработки персональных данных (далее - Политика) регулирует правоотношения по обработке персональных данных между пользователем сети “Интернет” (далее - Клиент) и Компанией.</p>
                    <p>2.Клиент обязан полностью ознакомиться с настоящей Политикой до момента использования сервисов Сайта.</p><p>3.В случае несогласия с положениями Политики Клиент должен воздержаться от дальнейшего использования Сайта.</p><p>4.Настоящая Политика действует только на территории Российской Федерации.</p><p>5.Компания вправе вносить в настоящую Политику изменения по своему усмотрению, в том числе, но не ограничиваясь, в случаях, когда соответствующие изменения связаны с изменениями в применимом законодательстве, а также когда соответствующие изменения связаны с изменениями в работе Сайтов. Клиент обязуется самостоятельно контролировать наличие изменений в настоящей Политике. Новая реакции Политики вступает в силу с момента ее размещения, если иное не предусмотрено новой редакцией Политики.</p>
                    <h5>2.Термины и определения</h5>
                    <p>1.В настоящей Политике, если из контекста не следует иное, нижеприведенные термины с заглавной буквы имеют следующие значения:1.«Компания» - юридическое лицо, ИП Вард Амалия Анатольевна (ИНН 165123295890; ОГРН 318169000012522), созданное в соответствии с законодательством Российской Федерации и осуществляющее предпринимательскую деятельность по продаже Товаров, в том числе дистанционным способом.</p>
                    <p>2.«Персональные данные» -  персональные данные, которые Пользователь может предоставить о себе (имя (в том числе псевдоним); день рождения; адрес доставки; номер телефона; адрес электронной почты); информация о географическом местоположении (если Пользователем было дано согласие владельцам операционных система (Apple, Android) на отслеживание местоположения своего мобильного устройства); информация об опыте работы; увлечениях; ссылки на личные страницы в социальных сетях; IP-адрес; информация из cookie; информация о браузере Пользователя, а также иные данные, которые Пользователь может указать/предоставить в соответствующих полях (как обязательных, так и необязательных для заполнения) на Сайте. Полный перечень Персональных данных указывается в соответствующих полях на каждом отдельном Сайте.</p>
                    <p>3.«Пользователь» (Клиент) -  Посетитель, который уже имеет личную учетную запись.</p>
                    <p>4.«Товары» -  продукция Компании, представленная на Сайте. </p>
                    <p>5.«Сайт, Веб – сайт» — веб-страница, содержащая контент (информацию) Продавца по оказанию услуг по договору купли-продажи Товаров дистанционным способом, имеющая уникальный адрес www.totospizza.ru , в том числе Мобильная версия, а также работающая по протоколу HTTPS для защиты персональных данных Покупателей.Для целей настоящей Политики термины и определения в единственном числе относятся также и к терминам и определениям во множественном числе и наоборот.</p>
                    <p>2.Настоящая Политика действует как для Сайта, так и для Мобильного приложения Toto’ Pizza (вне зависимости от платформы).</p>
                    <h5>3.Условия обработки персональных данных пользователей.</h5><p>Компания не проверяет достоверность персональной информации, предоставляемой Пользователями. Однако Компания исходит из того, что Пользователь предоставляет достоверную и достаточную персональную информацию, и поддерживает эту информацию в актуальном состоянии.</p>
                    <p>2.Под обработкой персональных данных в настоящей Политике понимается:  сбор Персональных данных, их систематизация, накопление, хранение, уточнение, извлечение, использование, передача, обезличивание, блокирование, удаление, уничтожение.</p>
                    <p>3.Цели обработки персональных данных:</p>
                    <p>1.Обработка заказа;</p>
                    <p>2.Оценка качества оказания услуг;</p>
                    <p>3.Ведение учета потребителей (клиентов).</p>
                    <p><b>4.Пользователь подтверждает, что внесение Пользователем своего номера телефона и числового кода, пришедшего в SMS означает согласие Пользователя со всеми положениями настоящей Политики. Обработка персональных данных осуществляется на основании договора розничной купли-продажи в соответствии п.5 ч.1 ст.6 Федеральным законом от 27.07.2006 N 152-ФЗ “О персональных данных”.</b></p>
                    <p>5.Пользователь  вправе требовать от Компании уточнения его персональных данных, их блокирования или уничтожения посредством направления в Компанию сообщения по электронной почте на адрес feedback@totospizza.ru.</p>
                    <p>6.Пользователь вправе изменить данные своей учетной записи на сайте или потребовать ее удаления, направив письмо по электронной почте на адрес feedback@totospizza.ru.</p>
                    <p>7.Срок обработки персональных данных производится при достижении целей обработки или в случае утраты в них необходимости, если иное не предусмотрено Федеральным законом "О персональных данных", либо при наступлении иных законных оснований.
                    </p>
                    <h5>4.Защита персональных данных Компанией</h5>
                    <p>1.Компания принимает необходимые и достаточные организационные и технические меры для защиты Персональных данных от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий с ней третьих лиц.</p>
                    <p>2.Компания хранит персональную информацию Пользователей, обеспечивая их конфиденциальность и защиту от неправомерного или случайного доступа к ним третьих лиц.</p><p>3.Компания обязуется предотвращать попытки несанкционированного доступа к персональным данным Пользователей, предоставленных Компании; своевременно обнаруживать и пресекать такие попытки.</p><p>4.Компания вправе произвести блокировку, исключение, удаление информации, размещенной Пользователем, без согласия последнего, если такая информация не отвечает требованиям действующего законодательства Российской Федерации и положениям настоящей Политики.</p>
                    <h5>5.Технология “Cookie”, используемая компанией на Сайте</h5>
                    <p>1. Компания вправе использовать технологию «cookie» для сохранения информации об ip-адресе Посетителей и Пользователей Сайта. «Cookie» не используются для сохранения конфиденциальной информации о Посетителях и Пользователях Сайта, и не направлены на установление личности Посетителя и Пользователей Сайта. Вся информация, которая собирается и анализируется, анонимна.</p><p>2.Использование технологии «cookie» представляет собой размещение на Сайте определенного набора символов  (знаков), которые сохраняются на компьютере (далее - “устройство”, с которого был выполнен вход на Сайт) Посетителей и Пользователей Сайта, при доступе к определенным местам Сайта.</p>3.Цели применения Компанией технологии «cookie»:1.аналитическая деятельность по исследованию предпочтений Посетителей и Пользователей Сайта;2.статистика по активности Посетителей и Пользователей на Сайте;3.повышение производительности и эффективности Сайта;4.аналитика и исправление ошибок Сайта, улучшение работы всех ресурсов Сайта;
                    <p>5.безопасность и целостность ресурсов Сайта.6.показ рекламных объявлений наших сервисов на сторонних сайтах.</p>

                </div>
            </section>
        </main>
    );
};

export default Policy;
