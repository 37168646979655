export const ADMIN_ROUTE = '/admin'
export const REGISTRATION_ROUTE = '/registration'
export const HOME_ROUTE = '/'
export const PROFILE_ROUTE = '/profile'
export const CART_ROUTE = '/cart'
export const CHECKOUT_ROUTE = '/checkout'
export const SALE_ROUTE = '/sale'
export const PRODUCT_ROUTE = '/product'
export const CATALOG_ROUTE = '/catalog'
export const DELIVERY_ROUTE = '/delivery'
export const CONTACTS_ROUTE = '/contacts'
export const ABOUT_ROUTE = '/about'
export const FAVORITES_ROUTE = '/favorites'
export const POLICY_ROUTE = '/policy'
export const TERMS_ROUTE = '/terms'
export const OFFER_ROUTE = '/offer'
export const PERSON_ROUTE = '/person'