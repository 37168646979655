import React from 'react';
import { Link, useParams } from "react-router-dom";
import { PROFILE_ROUTE, HOME_ROUTE } from "../../utils/consts";
import SideBar from "./components/menu";

const Terms = () => {
    const { id } = useParams()
    return (
        <main>
            <section id="sec-13" className="mb-8">
                <div className="container">
                    <div className="row">
                        <SideBar />
                        <div className="col-md-8 col-xl-7 col-xxl-6 offset-xl-1">
                            <h5>Условия использования</h5>
                            <div className="text">
                                <p>Поставщик услуг</p>
                                <p>Сервисы Google предоставляет следующая компания (с ней вы заключаете договор):</p>
                                <p>Google LLC</p>
                                <p>Если вы ещё не достигли требуемого возраста для управления аккаунтом Google, то можете использовать его только с разрешения родителя или законного представителя. Для этого родителю или законному представителю нужно прочитать эти условия вместе с Вами.</p>
                                <p>Если вы являетесь родителем или законным представителем ребёнка и позволяете ему использовать сервисы Google, настоящие условия относятся к вам и вы несёте ответственность за действия ребёнка в наших сервисах.</p>
                                <p>Для некоторых сервисов Google установлены особые возрастные ограничения. Они указаны в дополнительных правилах и условиях использования отдельных сервисов.</p>
                                <p>Возрастные ограничения</p>
                                <p>Если вы ещё не достигли требуемого возраста для управления аккаунтом Google, то можете использовать его только с разрешения родителя или законного представителя. Для этого родителю или законному представителю нужно прочитать эти условия вместе с Вами.</p>
                                <p>Если вы являетесь родителем или законным представителем ребёнка и позволяете ему использовать сервисы Google, настоящие условия относятся к вам и вы несёте ответственность за действия ребёнка в наших сервисах.</p>
                                <p>Для некоторых сервисов Google установлены особые возрастные ограничения. Они указаны в дополнительных правилах и условиях использования отдельных сервисов.</p>
                                <p>Поставщик услуг</p>
                                <p>Сервисы Google предоставляет следующая компания (с ней вы заключаете договор):</p>
                                <p>Если вы ещё не достигли требуемого возраста для управления аккаунтом Google, то можете использовать его только с разрешения родителя или законного представителя. Для этого родителю или законному представителю нужно прочитать эти условия вместе с Вами.</p>
                                <p>Если вы являетесь родителем или законным представителем ребёнка и позволяете ему использовать сервисы Google, настоящие условия относятся к вам и вы несёте ответственность за действия ребёнка в наших сервисах.</p>
                                <p>Для некоторых сервисов Google установлены особые возрастные ограничения. Они указаны в дополнительных Если вы ещё не достигли требуемого возраста для управления аккаунтом Google, то можете использовать его только с разрешения родителя или законного представителя. Для этого родителю или законному представителю нужно прочитать эти условия вместе с Вами.</p>
                                <p>Если вы являетесь родителем или законным представителем ребёнка и позволяете ему использовать сервисы Google, настоящие условия относятся к вам и вы несёте ответственность за действия ребёнка в наших сервисах.</p>
                                <p>Для некоторых сервисов Google установлены особые возрастные ограничения. Они указаны в дополнительных правилах и условиях использования отдельных сервисов.</p>
                            </div>
                            <Link to={PROFILE_ROUTE} className="gray-3 d-flex align-items-center">
                                <img src="/images/icons/chevron-left.svg" alt="Вернуться назад" className="me-1" />
                                Вернуться назад
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
};

export default Terms;
