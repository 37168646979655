import React, { useEffect } from 'react'

const Policy = () => {
    useEffect(() => {
        document.title = "Политика конфиденциальности"
    }, [])
    return (
        <main>
            <section id="sec-8" className="mb-8">
                <div className="container">
                    <h1>Политика конфиденциальности</h1>
                    <h5>1. Общие положения</h5>
                    <p>1.1. Настоящая Политика конфиденциальности (далее — «Политика») действует в отношениях между Пользователями и ИП Вард Амалия Анатольевна  (далее — «Компания»).</p>
                    <p>1.2. В рамках настоящей Политики под «персональной информацией Пользователя» понимаются: данные, которые Пользователь предоставляет о себе (фамилию, имя, отчество; дата рождения; пол; адрес доставки; номер телефона; адрес электронной почты)</p>
                    <p>1.3. Компания не проверяет достоверность персональной информации, однако подразумевает, что Пользователь предоставляет достоверную персональную информацию, и поддерживает эту информацию в актуальном состоянии.</p>
                    <p>1.4. Компания не контролирует и не несет ответственность за сторонние сайты, на которые Пользователь может перейти по ссылкам, доступным на сайтах Компании. На таких сайтах у Пользователя может собираться или запрашиваться иная персональная информация, а также могут совершаться иные действия.</p>
                    <p>1.5. Под обработкой персональных данных в настоящей Политике понимается: сбор персональных данных, их накопление, хранение, обновление (изменение), использование, блокировка и иное.</p>
                    <br />
                    <h5>2. Цели сбора и обработки персональной информации Пользователей:</h5>
                    <p>2.1. идентификация Пользователя Компанией для продажи товаров дистанционным способом, оказания услуг по заказу и доставке товаров Компании, а также обработка запросов и заявок от Пользователя;</p>
                    <p>2.2. связь с Пользователем, в том числе направление уведомлений, запросов и информации, касающихся Компании;</p>
                    <p>2.3. направление кассового чека в электронном виде на адрес электронной почты и/или телефон Пользователя;</p>
                    <p>2.4. рекламы своих товаров и услуг;</p>
                    <p>2.5. иных целях, не противоречащих действующему законодательству РФ.</p>
                    <br />
                    <h5>3. Условия обработки персональной информации Пользователя и её передачи третьим лицам</h5>
                    <p>3.1. Использование Сервисов означает согласие Пользователя с Политикой Компании. В случае несогласия с этими условиями Пользователь должен воздержаться от использования Сервисов.</p>
                    <p>3.2. Обработка таких персональных данных, как: фамилия, имя, отчество; дата рождения; пол; адрес доставки, телефон, адрес электронной почты может осуществляться оператором Компании независимо от получения и/или неполучения от Пользователя согласия на их обработку, если указанные персональные данные необходимы Компании для выполнения заказа и доставки товаров Пользователю.</p>
                    <p>3.3. Компания хранит персональную информацию Пользователей, обеспечивая их конфиденциальность и защиту от неправомерного или случайного доступа к ним третьих лиц.</p>
                    <p>3.4. Компания вправе передать персональную информацию Пользователя третьим лицам в следующих случаях:</p>
                    <p>3.4.1. в целях доставки товаров Пользователю посредством курьерской службы Компании;</p>
                    <p>3.4.2. в рамках продажи или иной передачи бизнеса (полностью или в части), при этом к приобретателю переходят все обязательства по соблюдению условий настоящей Политики применительно к полученной им персональной информации;</p>
                    <p>3.4.3. в целях обеспечения возможности защиты прав и законных интересов Компании или третьих лиц в случаях, когда Пользователь нарушает условия Публичной оферты;</p>
                    <p>3.4.4. в целях сохранения и проведения анализа истории заказов товаров Пользователем посредством использования операторов Компании, осуществляющих прием заказов товаров по телефону;</p>
                    <br />
                    <h5>4. Меры, применяемые для защиты персональной информации Пользователей</h5>
                    <p>4.1. Компания принимает необходимые организационные и технические меры для защиты персональной информации Пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий с ней третьих лиц.</p>
                    <p>4.2. Компания обеспечивает конфиденциальность предоставленных Пользователем персональных данных, их защиту от копирования, распространения.</p>
                    <br />
                    <h5>5. Изменение Политики конфиденциальности</h5>
                    <p>5.1. Компания имеет право вносить изменения в настоящую Политику. Новая редакция Политики вступает в силу с момента ее размещения, если иное не предусмотрено новой редакцией Политики. Действующая редакция всегда находится на Сайте. Поэтому Пользователь обязуется самостоятельно контролировать наличие изменений в настоящей Политике.</p>
                    <br />
                    <h5>6. Заключительные положения</h5>
                    <p>6.1. Настоящая Политика регулируется нормами действующего законодательства РФ.</p>
                    <p>6.2. Все возможные споры относительно настоящей Политики разрешаются согласно нормам действующего законодательства РФ.</p>
                    <p>6.3 Ввиду предоставления Пользователями своих персональных данных при регистрации на Сайте, Компанией совершает действия, направленные на сбор, запись, хранение и систематизацию указанных данных физических лиц с, предусмотренных настоящим Пользовательским соглашением, соблюдением требований, предусмотренных Федеральным законом от 27.07.2006 N 152-ФЗ «О персональных данных», и в соответствии с разработанной Компанией Политикой конфиденциальности.</p>
                </div>
            </section>
        </main>
    );
};

export default Policy;
